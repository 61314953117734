import { useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from './../Components/Layout';
import { Grid, Paper, Stack, Typography, Avatar, Button, TextField, LinearProgress, Box, Divider, CircularProgress } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { LoadingButton } from '@mui/lab';
import UserIcon from './../Assests/Icons/user.png'
import ArrowLeftIcon from './../Assests/Icons/arrow-left.svg'
import UserPlusIcon from './../Assests/Icons/user-plus.png'
import PhonePassIcon from './../Assests/Icons/phone-password.png'
import CheckIcon from './../Assests/Icons/check-circle.png'
import { useEffect, useState } from 'react';
import DataService from './../Components/DataService'
import BranchContext from '../Contexts/branch';
import LoadingPage from './Loading';
import LoadingOverlay from './../Components/LoadingOverlay'
import toast from 'react-hot-toast';

function Form() {

  const [loading, setLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { hash } = useParams();
  const [step, setStep] = useState(1)
  const navigate = useNavigate();
  const { setBranchData, branchData } = useContext(BranchContext);

  useEffect(() => {
    DataService.get(`?hash=${hash}`).then(res => {
      if (res.data.status === false) {
        navigate('/404')
      } else {
        const d = res.data.data;
        setBranchData(d)
        setLoading(false);
      }
    })
  }, [hash])


  if (loading)
    return <LoadingPage />

  return (
    <Layout>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: 'calc(100% - 55px)' }}
      >
        {step === 1 && <EmployeesSelection data={branchData} setStep={setStep} setSelectedEmployee={setSelectedEmployee} />}
        {step === 2 && <Step2 setStep={setStep} />}
        {step === 3 && <VerifyOTP setStep={setStep} selectedEmployee={selectedEmployee} />}
        {step === 4 && <Questions setStep={setStep} data={branchData} selectedEmployee={selectedEmployee} />}
        {/* {step === 5 && <YesOrNoQuestion setStep={setStep} />}
        {step === 6 && <TextQuestion setStep={setStep} />} */}
        {step === 5 && <Success setStep={setStep} />}
      </Grid>
    </Layout >
  );
}

export default Form;


function EmployeesSelection({ setStep, data, setSelectedEmployee }) {

  const [loading, setLoading] = useState(null);
  async function SendOtp(item) {

    setLoading(item.id);
    const res = await DataService.put(`sendOtp?employeeId=${item.id}`);
    setLoading(null);
    if (res.data.status) {
      setSelectedEmployee(item);
      setStep(3);
    }

  }
  const Item = ({ item }) => {
    return (
      <Paper onClick={() => { loading === null && SendOtp(item) }} elevation={0} sx={{ height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', pl: '20px', pr: '20px', boxShadow: '0px 3px 14px #00000012', borderRadius: '15px', ':hover': { bgcolor: 'lightgray', cursor: 'pointer' } }}>
        <Typography sx={{ font: 'normal normal 600 22px/17px Assistant' }} color="primary"><img style={{ height: '20px', position: 'relative', top: '3px', marginLeft: '15px' }} src={UserIcon} />{item.name}</Typography>
        {loading === item.id ? <CircularProgress /> : <Avatar sx={{ bgcolor: '#3CB450', width: '33px', height: '33px' }}><img height="50%" src={ArrowLeftIcon} /></Avatar>}
      </Paper>
    )
  }
  return (
    <Grid item xs={12} sm={10} md={8} lg={4} xl={4}>
      <Typography sx={{ mb: 4, font: 'normal normal 600 36px/28px Assistant' }} align="center" color="primary">אנא בחרו אחמ"ש</Typography>
      <Stack spacing={1}>
        {data.employees.map((x, i) => (<Item key={i} item={x} />))}
        {/* <Item text="אריאל כהן" />
        <Item text="ישראל ישראלי" />
        <Item text="ירון שלום" />
        <Item text="אנסטסיה ישראל" />
        <Item text="ליאור כהן" />
        <Item text="רותם לוי" /> */}
        <LoadingButton sx={{ height: '55px', borderRadius: '15px', font: 'normal normal normal 21px/16px Assistant' }} variant="contained" color="primary"><span>לא ברשימה?</span><span style={{ textDecoration: 'underline', marginRight: '5px', font: 'normal normal 600 21px/16px Assistant' }}>לחץ כאן</span></LoadingButton>
      </Stack>
    </Grid>
  )
}

function Step2({ setStep }) {
  return (
    <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
      <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: '60px', py: '60px', boxShadow: '0px 3px 14px #00000012' }}>
        <img width="105px" src={UserPlusIcon} />
        <Typography sx={{ mb: 4, font: 'normal normal 600 36px/28px Assistant' }} align="center" color="primary">אנא בחרו אחמ"ש</Typography>
        <Stack sx={{ width: '100%' }} spacing={1}>
          <TextField InputLabelProps={{ sx: { opacity: 1, color: '#32345EF', font: 'normal normal 600 22px/17px Assistant', mt: 1 } }} InputProps={{ sx: { height: '66px', font: 'normal normal 600 22px/17px Assistant' } }} label="שם מלא" />
          <TextField InputLabelProps={{ sx: { opacity: 1, color: '#32345EF', font: 'normal normal 600 22px/17px Assistant', mt: 1 } }} InputProps={{ sx: { height: '66px', font: 'normal normal 600 22px/17px Assistant' } }} label="טלפון" />
          <Button sx={{ font: 'normal normal 600 22px/17px Assistant', height: '66px' }} onClick={() => setStep(3)} variant="contained" color="primary"> שלח קוד אימות ב SMS</Button>
        </Stack>
      </Paper>
    </Grid>
  )
}

function VerifyOTP({ setStep, selectedEmployee }) {

  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(false);

  async function VerifyOTP() {

    setLoading(true);
    const res = await DataService.get(`verifyOtp?employeeId=${selectedEmployee.id}&otp=${otp}`);
    setLoading(false);
    if (res.data.status) {
      setStep(4);
    } else {
      toast.error(res.data.message)
    }

  }
  return (
    <Grid item xs={12} sm={8} md={6} lg={5} xl={5}>
      <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: '60px', py: '60px', boxShadow: '0px 3px 14px #00000012' }}>
        <img width="73px" src={PhonePassIcon} />
        <Typography align="center" sx={{ font: 'normal normal 600 36px/28px Assistant', mt: '9px' }} color="primary">הזנת קוד אימות</Typography>
        <Typography sx={{ mb: 3, font: 'normal normal normal 20px/28px Assistant' }} align="center" color="primary">למספר {selectedEmployee?.phone} SMS יש להזין את הקוד שקבלת ב</Typography>
        <MuiOtpInput value={otp} onChange={(v) => setOtp(v)} sx={{ mb: 3, '.MuiOutlinedInput-input': { height: '96px', width: '86px', fontSize: '50px' } }} />
        <LoadingButton disabled={otp.length !== 4} loading={loading} onClick={() => VerifyOTP()} fullWidth variant="contained" sx={{ bgcolor: '#3CB450', height: '66px', font: 'normal normal 600 22px/17px Assistant' }} color="success">כניסה</LoadingButton>
        <Typography sx={{ mt: 2, color: '#696A7E', font: 'normal normal normal 16px/28px Assistant' }} variant="button">לא קיבלת את הקוד? <span style={{ textDecoration: 'underline' }}>שלח שוב</span></Typography>
      </Paper>
    </Grid>
  )
}

function Success({ setStep }) {

  return (
    <Grid item xs={12} sm={8} md={6} lg={3} xl={3}>
      <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: '60px', py: '60px', boxShadow: '0px 3px 14px #00000012' }}>
        <img src={CheckIcon} width="106px" />
        <Typography sx={{ mt: '19px', font: 'normal normal 600 36px/28px Assistant' }} align="center" variant='h5' color="primary">!תודה רבה</Typography>
        <Typography sx={{ mt: 1, font: 'normal normal normal 20px/28px Assistant' }} align="center" color="primary">המשוב שלך נקלט בהצלחה</Typography>

        <Divider variant="fullWidth" flexItem sx={{ my: 3, mx: 5 }} />
        <Typography sx={{ font: 'normal normal normal 20px/28px Assistant' }} color="primary" variant="button">חזרה בעוד 5 שניות</Typography>
      </Paper>
    </Grid>
  )
}

function Questions({ setStep, data, selectedEmployee }) {

  const [currentIndex, setCurrentIndex] = useState(0)
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleAnswer(ans) {
    const d = [...answers];
    const index = d.findIndex(x => x.questionId === data.questions[currentIndex].id);
    if (index !== -1)
      d[index].answerText = ans;
    else
      d.push({ id: 0, questionId: data.questions[currentIndex].id, employeeId: selectedEmployee.id, answerText: ans })
    setAnswers(d);
    if (data.questions.length > currentIndex + 1)
      setCurrentIndex(currentIndex + 1)
    else
      SubmitData(d);

  }
  async function SubmitData(d) {
    setLoading(true);
    const res = await DataService.post(``, d);
    setLoading(false);
    if (res.data.status) {
      setStep(5);
    } else {
      toast.error(res.data.message)
    }
  }

  const totalItems = useMemo(() => {
    return data.questions?.length
  }, [data])
  return (
    <Grid item xs={12} sm={10} md={8} lg={8} xl={6}>
      <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: '60px', py: '60px', boxShadow: '0px 3px 14px #00000012' }}>
        {loading && <LoadingOverlay />}
        {data.questions?.map((x, i) => i === currentIndex && <Question key={i} item={x} handleSuccess={(d) => handleAnswer(d)} />)}
        <span onClick={() => setCurrentIndex(currentIndex - 1)} style={{ marginTop: '25px', marginBottom: '25px', visibility: currentIndex === 0 ? 'hidden' : 'unset' }}>&#8249; <Typography sx={{ textDecoration: 'underline', color: '#696A7E', font: 'normal normal normal 18px/28px Assistant' }} variant="button">חזרה לשאלה הקודמת</Typography></span>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
          <Box sx={{ maxWidth: '680px', width: '680px', mr: 1 }}>
            <LinearProgress variant="determinate" value={((currentIndex + 1) / totalItems) * 100} />
          </Box>
          <Box sx={{ minWidth: 100 }}>
            <Typography variant="body2" color="text.secondary">{`שאלה ${currentIndex + 1} מתוך ${totalItems}`}</Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

function Question({ item, handleSuccess }) {
  if (item.type === 1)
    return <TextQuestion item={item} handleSuccess={handleSuccess} />
  else if (item.type === 2)
    return <RatingQuestion item={item} handleSuccess={handleSuccess} />
  else if (item.type === 3)
    return <YesOrNoQuestion item={item} handleSuccess={handleSuccess} />
}

function RatingQuestion({ item, handleSuccess }) {

  const Item = ({ text, color }) => {
    return (
      <Button onClick={() => handleSuccess(text)} sx={{ minWidth: '70px', maxWidth: '70px', height: '84px', px: 1, font: 'normal normal 600 35px/34px Assistant', borderRadius: '10px' }} variant="contained" color={color}>{text}</Button>
    )
  }
  return (
    <Box>
      <Typography sx={{ mb: '41px', font: 'normal normal 600 36px/28px Assistant' }} align="center" color="primary">{item.questionText}</Typography>
      <Stack spacing={1} direction="row">
        <Item text="10" color="success" />
        <Item text="9" color="success" />
        <Item text="8" color="success" />
        <Item text="7" color="warning" />
        <Item text="6" color="warning" />
        <Item text="5" color="warning" />
        <Item text="4" color="warning" />
        <Item text="3" color="error" />
        <Item text="2" color="error" />
        <Item text="1" color="error" />
      </Stack>
    </Box>
  )
}
function YesOrNoQuestion({ item, handleSuccess }) {

  return (
    <Box >
      <Typography sx={{ mb: '41px', font: 'normal normal 600 36px/28px Assistant' }} align="center" color="primary">{item.questionText}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button onClick={() => handleSuccess("כן")} fullWidth sx={{ height: '84px', font: 'normal normal 600 35px/34px Assistant' }} variant="contained" color="success">כן</Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button onClick={() => handleSuccess("לא")} fullWidth sx={{ height: '84px', font: 'normal normal 600 35px/34px Assistant' }} variant="contained" color="error">לא</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

function TextQuestion({ item, handleSuccess }) {

  const [text, setText] = useState('')
  const [error, setError] = useState(false);
  return (
    <Box sx={{ maxWidth: '500px', width: '100%' }}>
      <Typography sx={{ mb: 3, font: 'normal normal 600 36px/28px Assistant' }} align="center" color="primary">{item.questionText}</Typography>
      <TextField
        InputLabelProps={{ sx: { opacity: 1, color: '#32345EF', font: 'normal normal 600 22px/17px Assistant', mt: 2 } }}
        InputProps={{ style: { height: '77px', font: 'normal normal 600 22px/17px Assistant' } }}
        fullWidth
        value={text}
        onChange={(e) => setText(e.target.value)}
        label="כתבו כאן הערות אם תרצו להוסיף משהו"
      />
      <Button onClick={() => handleSuccess(text)} sx={{ mt: 2, height: '72px', font: 'normal normal 600 35px/34px Assistant', bgcolor: '#3CB450' }} fullWidth variant="contained" color="success">לא</Button>
    </Box>
  )
}

