import { Box, Typography } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading() {

    return (
        <Box sx={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <CircularProgress size="4rem"/>
        </Box>
    )
}