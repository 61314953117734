import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Form from './Pages';
import NotFound from './Pages/NotFound';
import { useState } from 'react';

function App() {

  const [step, setStep] = useState(1)
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/b/:hash" element={<Form />}>
          {/* <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
