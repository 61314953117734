import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { BranchHook } from './Contexts/branch';
import { Toaster } from 'react-hot-toast';
const theme = createTheme({
  shape: {
    borderRadius: 15,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "fieldset": {
              border: "1px solid #32345E",
            },
            "&:hover fieldset": {
              borderColor: "2px solid #32345E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "2px solid #32345E",
            },
          },
        },
      }
    }
  },
  direction: 'rtl',
  typography: {
    fontFamily: '"Assistant", sans-serif',
  },
  palette: {
    primary: {
      light: '#5b5c7e',
      main: '#32345E',
      dark: '#232441',
      contrastText: '#fff',
    },
    secondary: {
      light: '#dd33fa',
      main: '#d500f9',
      dark: '#9500ae',
      contrastText: '#fff',
    }
  }
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>
      <BranchHook>
        <App />
        <Toaster position="bottom-center" />
      </BranchHook>
    </ThemeProvider>
  </CacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
