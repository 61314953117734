import { Dialog, DialogContent, CircularProgress } from "@mui/material";



export default function LoadingOverlay() {


    return (
        <Dialog open fullWidth hideBackdrop maxWidth="sm" PaperProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.8)', boxShadow: 'none' } }}>
            <DialogContent sx={{ height:'200px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>
                <CircularProgress color="success" size="4rem" />
            </DialogContent>
        </Dialog>
    )
}