import { Box, Typography } from "@mui/material"


export default function NotFound() {

    return (
        <Box sx={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <Typography variant="h3">404 - Page Not Found</Typography>
        </Box>
    )
}