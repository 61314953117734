import axios from 'axios';
// import i18next from 'i18next';
// import toast from 'react-hot-toast';
// const API_ENDPOINT = "https://localhost:55964/api"
const API_ENDPOINT = "https://5kc77u64b1.execute-api.eu-west-2.amazonaws.com/Prod/api"

const authHeader = () => ({
   Authorization: `Bearer ${window ? window.localStorage.getItem('accessToken') : ''}`,
});

const client = axios.create({
   baseURL: API_ENDPOINT,
   headers: {
      //Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
   },
});

class DataService {

   static get(path = '') {
      return client({
         method: 'GET',
         url: path,
         headers: { ...authHeader() },
      });
   }

   static getFile(path = '') {
      return client({
         method: 'GET',
         url: path,
         responseType: 'blob',
         headers: { ...authHeader() },
      });
   }

   static post(path = '', data = {}, optionalHeader = {}) {
      return client({
         method: 'POST',
         url: path,
         data,
         headers: { ...authHeader(), ...optionalHeader },
      });
   }

   static patch(path = '', data = {}) {
      return client({
         method: 'PATCH',
         url: path,
         data: JSON.stringify(data),
         headers: { ...authHeader() },
      });
   }

   static delete(path = '', data = {}) {
      return client({
         method: 'DELETE',
         url: path,
         data: JSON.stringify(data),
         headers: { ...authHeader() },
      });
   }

   static put(path = '', data = {}, optionalHeader = {}) {
      return client({
         method: 'PUT',
         url: path,
         data: JSON.stringify(data),
         headers: { ...authHeader(), ...optionalHeader },
      });
   }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
   // do something before executing the request
   // For example tag along the bearer access token to request header or set a cookie
   const requestConfig = config;
   const { headers } = config;

   //requestConfig.headers = { ...headers, Authorization: `Bearer ${window.localStorage.getItem('accessToken')}` };

   return requestConfig;
});

client.interceptors.response.use(
   response => response,
   error => {
      const { response } = error;
      const originalRequest = error.config;
      if (response) {
         if (response.status === 500) {
            //   toast.error(i18next.t("500", { ns: "Error" }))
         } else if (response.status === 400) {
            return response;
         } else if (response.status === 401) {
            //  toast.error(i18next.t("401", { ns: "Error" }))
         } else if (response.status === 403) {
            //  toast.error(i18next.t("403", { ns: "Error" }))
         } else {
            return originalRequest;
         }
      }
      //toast.error(i18next.t("Network_Error", { ns: "Error" }))

      return Promise.reject(error);
   },
);


export default DataService
