import { useState, createContext, useContext } from "react";
import ReactDOM from "react-dom/client";

const BranchContext = createContext();

function BranchHook({ children }) {
    const [branchData, setData] = useState({ branchName: 'חוצות המפרץ אאוטלט', logo: '/factory54.png', employees: [] });

    const setBranchData = (d) => {
        setData(d);
    };
    return (
        <BranchContext.Provider value={{ branchData, setBranchData }}>
            {children}
        </BranchContext.Provider>
    );
}

export default BranchContext;
export { BranchHook };