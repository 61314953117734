import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import Logo from '../../Assests/logo.svg'
import LogoutIcon from '../../Assests/Icons/logout.png'
import ChatIcon from '../../Assests/Icons/chat.png'
import BranchContext from "../../Contexts/branch";
const Layout = (props) => {

    const { branchData, setBranchData } = useContext(BranchContext);
    return (
        <Box sx={{ minHeight: '100vh', overflow: 'auto' }}>
            <Box sx={{ height: 'calc(100vh - 40px)' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: '30px', px: '25px' }}>
                    <Typography sx={{ font: 'normal normal 600 17px/14px Assistant' }} color="primary">מחובר על סניף
                        {/* <span style={{ textDecoration: 'underline', font: 'normal normal bold 17px/14px Assistant' }}> חוצות המפרץ אאוטלט</span> */}
                        <span style={{ textDecoration: 'underline', font: 'normal normal bold 17px/14px Assistant' }}> {branchData.branchName}</span>
                        <img style={{ top: '5px', right: '5px', position: 'relative' }} height="23px" src={LogoutIcon} />
                    </Typography>
                    <img style={{ height: '30px' }} src={branchData.logo} />
                </Box>
                {props.children}
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor: 'white', height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', marginRight: '25px' }}>
                    <img style={{ top: '3px', left: '10px', position: 'relative' }} height="20px" src={ChatIcon} />
                    <Typography style={{ font: 'normal normal 600 16px/17px Assistant' }} color="primary">זקוק לתמיכה?
                        <span style={{ textDecoration: 'underline' }}> לחץ כאן</span>
                    </Typography>
                </div>

                <div style={{ display: 'flex', marginLeft: '25px' }}>
                    <Typography sx={{ marginRight: '10px', font: 'normal normal 600 16px/17px Assistant' }} color="primary">מופעל ע"י</Typography>
                    <img height="18px" src={Logo} />
                </div>

            </Box>
        </Box>
    )
}

export default Layout;